import {__t} from "@commons/i18nRef";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";

__webpack_public_path__ = cpAppSet.baseUrl + "theme/public/js/app/";

/*var headers = {};
 headers[cpAppSet.csrfToken.name] = cpAppSet.csrfToken.value;
 
 $.ajaxSetup({
 headers: headers
 });*/

$.ajaxSetup({
    beforeSend: function (jqxhr, settings) {
        if (!settings.crossDomain) {
            jqxhr.setRequestHeader(cpAppSet.csrfToken.name, cpAppSet.csrfToken.value);
        }
    }
});

//--------Override datepicker: https://stackoverflow.com/questions/24500726/replace-the-jquery-datepicker-dateformat-with-momentjs-parsing-and-format
$.datepicker.parseDate = function (format, value) {
    if (format === null || value === null) {
        throw "Invalid arguments";
    }

    value = (typeof value === "object" ? value.toString() : value + "");
    if (value === "") {
        return null;
    }

    var result = moment(value, format);
    if (result.isValid()) {
        return result.toDate();
    } else {
        console.log("Dp invalid date", value, format);
        return null;
    }
};

$.datepicker.formatDate = function (format, date) {
    if (!date) {
        return "";
    }
    return moment(date).format(format);
};

//-------Try guess timezone--------------------------------
try {
    if (moment && moment.tz && Cookies.get('csTz') == undefined) {
        var csTz = moment.tz.guess();
        if (csTz) {
            Cookies.set('csTz', csTz, {expires: 90});
        }
    }
} catch (e) {
    console.log("Unable to guess tz");
}

//---Layout--------------------------------
function setLayout() {
    $('body').trigger('before-layout-setup');

    if ($("header").length && $("footer").length) {
        //var contentMinHeight = $(window).height() - $("header").outerHeight();
        var contentMinHeight = $(window).height();

        if ($("header").css('position') !== 'absolute' && $("header").css('position') !== 'fixed') {
            var contentMinHeight = contentMinHeight - $("header").outerHeight();
        }

        if (!$("body").hasClass("full-height-content")) {
            var contentMinHeight = contentMinHeight - $("footer").outerHeight();
        }

        if ($("body").hasClass("inwebview")) {// Senza header footer
            var contentMinHeight = $(window).height();
        }

        if ($.isNumeric(contentMinHeight) && contentMinHeight > 0) {
            $("main").css('min-height', contentMinHeight);
            //console.log(contentMinHeight);
        }
    }

    $('body').trigger('after-layout-setup');
}

//Utility same height hinner block
$('body').on('after-layout-setup', function () {

    $(".childs-force-same-height").each(function (index) {
        $(".target-same-height", this).css('height', "auto");

        var maxHeight = 0;
        $(".target-same-height", this).each(function (index) {
            maxHeight = Math.max(maxHeight, $(this).outerHeight());
        });

        if (maxHeight && $(window).width() > 768) {
            $(".target-same-height", this).css('height', (maxHeight) ? maxHeight : "auto");
        }
    });

});

//Smart resize event
(function () {
    var resize_timer;
    $(window).on('resize', function () {
        clearTimeout(resize_timer);
        resize_timer = setTimeout(function () {
            setLayout();
            $('body').trigger('wsmart_resize');
        }, 70);
    });
})();

$(window).on('load', function () {
    setLayout();
    /*setTimeout(function () {
     //setLayout();// TODO: Salta?        
     }, 100);*/
});

setLayout();

//---Smooth Scrolling--------------------------------
$('a.smooth-scrolling').click(function () {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
        var scrollTop = target.offset().top;
        if ($("header").length) {
            scrollTop -= $("header").height();
        }
        $('html,body').stop().animate({scrollTop: scrollTop}, 1000);
        return false;
    }
});

//---Main menu hover open--------------------------------
/*var mainMenuItems = $('header .navbar-nav > .dropdown');
 $(mainMenuItems).each(function (index) {
 var menu_timer;
 var t = this;
 $(this).on("mouseenter", function () {
 clearTimeout(menu_timer);
 $(mainMenuItems).find(" > .dropdown-menu").removeClass('show');
 $(t).find(" > .dropdown-menu").addClass('show');
 }).on("mouseleave", function () {
 menu_timer = setTimeout(function () {
 $(t).find(" > .dropdown-menu").removeClass('show');
 }, 250);
 });
 });
 */

//---Scrolling to top-------------------------------
$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('.scrolltop:hidden').stop(true, true).fadeIn();
    } else {
        $('.scrolltop').stop(true, true).fadeOut();
    }
});
$(".scroll").click(function () {
    var offset_top = 0;
    if ($(".main-nav-bar").is(":visible") && !$(".main-nav-bar").hasClass("navbar-fixed-top")) {
        offset_top = $(".main-nav-bar").offset().top;
    }

    $("html,body").animate({scrollTop: offset_top}, "800");
    return false;
});

//---Password Toggle--------------------------------
$('.toggle-password').each(function () {
    var t = this;
    var btn = $(".input-group-text", this);
    var input = $("input", this);

    $(btn).click(function (e) {
        $(t).toggleClass("hide-text ");
        $(input).attr('type', ($(t).hasClass("hide-text") ? "text" : "password"));
    });
});

//---Prevent back button--------------------------------
$(window).on("load", function () {

    window.setTimeout(function () {//autocomplete wait?!

        var isPost = false;
        $("form.dirty-prevent-back").each(function (index) {
            var form = $(this);
            var origFormData = $(form).serialize();

            $("input,select,textarea", form).on('change input paste', function (e) {
                if ($(form).serialize() !== origFormData) {
                    $(form).addClass("is-dirty");
                } else {
                    $(form).removeClass("is-dirty");
                }
            });

            $(form).on("submit", function () {
                isPost = true;
            });

        });

        $(window).on('beforeunload', function () {
            if (isPost || $("form.dirty-prevent-back.is-dirty").length === 0) {
                return;
            }
            return __t("Vuoi uscire senza salvare?");
        });


    }, 900);

});

// Flag body class when page unload
$(window).on('beforeunload', function () {
    $("body").addClass("page-unload");
});

//sa2 helper
$(".sa2-link-confirm").on("click", function (e) {
    e.preventDefault();
    var href = $(this).attr("href");
    var msg = $(this).data("msg");
    msg = msg || __t("Confermi?");

    sa2Confirm(msg, function () {
        window.location.href = href;
    });
});

$(".sa2-click-prevent-alert").on("click", function (e) {
    e.preventDefault();
    var msg = $(this).data("msg");
    msg = msg || __t("Attenzione");
    sa2Alert(msg);
});

$(".sa2-submit-confirm").on("click", function (e) {
    e.preventDefault();
    var form = $(this).closest("form");
    var msg = $(this).data("msg");
    msg = msg || __t("Confermi?");

    sa2Confirm(msg, function () {
        if ($(".submit-loader-overlay", form).length) {
            $(".submit-loader-overlay", form).show();
        }
        $(form).submit();
    });
});